import dayjs from 'dayjs';

import { sendEvent } from '../sendEvent';
import { TrackingEvent, EcommerceTrackingObject, ProductType } from '../types';
import { OfferDetails, ProductTrackingList } from '@AuroraTypes';
import { ISO_DATE_FORMAT } from '@Dates/dates';
import { SearchSelectionStore } from '@Stores/SearchSelectionStore';
import { getAdultsAndChildren } from '@Stores/searchStoreTracker';

export const useProductDetailsPandaViewTracking =
  () =>
  (
    offerDetails: OfferDetails,
    searchSelectionStore: Pick<SearchSelectionStore, 'rooms' | 'masterId' | 'nights'>,
    listOverride?: ProductTrackingList,
  ) => {
    const { pricing, hotel, flights, isPackageRefundable } = offerDetails;
    const {
      accommodation: { destination, name },
    } = hotel;
    const { total_adults: adults, total_children: children } = getAdultsAndChildren(
      searchSelectionStore.rooms,
    );

    const destinationName = destination?.region
      ? destination?.region.name
      : flights.outbound.arrivalAirport.name;

    const trackingObject: EcommerceTrackingObject = {
      event: TrackingEvent.productDetailsView,
      list: listOverride || 'SelectedOffer',
      id: searchSelectionStore.masterId,
      masterId: searchSelectionStore.masterId,
      name,
      price: pricing.total,
      checkInDate: dayjs(flights.outbound.departureDate).format(ISO_DATE_FORMAT),
      checkOutDate: dayjs(flights.inbound.departureDate).format(ISO_DATE_FORMAT),
      nights: searchSelectionStore.nights,
      rooms: searchSelectionStore.rooms.length,
      adults,
      children,
      destination: destinationName,
      departureAirport: flights.outbound.departureAirport.id,
      arrivalAirport: flights.outbound.arrivalAirport.id,
      category: ProductType.dynamicPackage,
      refundablePackage: isPackageRefundable,
      isLuggageIncluded: flights.luggage.isIncluded,
    };

    sendEvent(trackingObject);
  };
