import loadable from '@loadable/component';
import { useEffect } from 'react';

import { LoadablePandaPage as LoadableNonGenericPandaPage } from './LoadableNonGenericPanda';
import { LoadablePandaLoadingLayout } from './LoadablePandaLoadingLayout';
import { LoadableGalleryModal } from '@Components/Modal/LoadableGalleryModal';
import { LoadableMasonryGridModal } from '@Components/NewGallery/LoadableMasonryModal';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { requestIdleCallback } from '@Core/requestIdleCallback';
import { LoadableGenericPage } from '@Pages/generic/LoadableGenericPage';

const loadableGenericPandaPage = loadable(
  () =>
    import(
      /* webpackChunkName: "LC-PandaPageComponent" */ '@Components/LayoutComponents/PandaPageComponent'
    ),
);

export function usePreloadPandaPage() {
  const newProductCardGallery = useFeatureFlag('NewProductCardGallery');
  const isGenericPandaEnabled = useFeatureFlag('GenericSRPPanda');

  useEffect(() => {
    requestIdleCallback(() => {
      LoadablePandaLoadingLayout.preload();

      if (newProductCardGallery) {
        LoadableGalleryModal.preload();
      } else {
        LoadableMasonryGridModal.preload();
      }

      if (isGenericPandaEnabled) {
        LoadableGenericPage.preload();
        loadableGenericPandaPage.preload();
      } else {
        LoadableNonGenericPandaPage.preload();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
