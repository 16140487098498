import { useLayoutEffect } from '@loveholidays/design-system';
// eslint-disable-next-line import/no-extraneous-dependencies
import { History, Location } from 'history';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAppContext } from '@Contexts/contexts';

interface LocationState {
  sunrise?: boolean;
}

/**
 * Adds `sunrise=true` to the location state if it's not there already.
 */
const patchLocationState = (location: Location<LocationState>, replace: History['replace']) => {
  if (!location.state?.sunrise) {
    replace({
      ...location,
      state: {
        ...location.state,
        sunrise: true,
      },
    });
  }
};

/**
 * Adds `sunrise=true` to the navigation states, so when navigating back we can check for
 * `sunrise=true` in the previous state to decide whether to do a full page reload or
 * a client side navigation.
 */
export const NavigationInterceptor: React.FC = () => {
  const { listen, location, replace } = useHistory<LocationState>();
  const { isWebView } = useAppContext();

  useEffect(() => {
    // Patches current location state
    patchLocationState(location, replace);

    const listener = listen((location, action) => {
      const { state = {} } = location;

      // Patches location state when navigation forward
      if (['REPLACE', 'PUSH'].includes(action)) {
        patchLocationState(location, replace);
      }

      // On backward navigation, do a page reload if the previous page was not a Sunrise page
      if (action === 'POP' && !state.sunrise) {
        window.location.reload();
      }
    });

    return () => listener();
  });

  useLayoutEffect(() => {
    const listener = listen((location) => {
      if (isWebView && window.sendFrontierEvent) {
        window.sendFrontierEvent({ name: 'sunriseLocationChange', message: location.pathname });
      }
    });

    return () => listener();
  });

  return null;
};
