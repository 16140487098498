import dayjs, { Dayjs } from 'dayjs';
import { SetState } from 'zustand';

import { getUrlFromSearchSelection } from './getUrlFromSearchSelection';
import {
  CancellationPolicy,
  DynamicPackageFiltersInput,
  PageType,
  RoomConfiguration,
  RoomConfigurationInput,
  SortOption,
} from '@AuroraTypes';
import { isMonthFlexibility } from '@Components/SearchForm/isMonthFlexibility';
import { DEFAULT_FLEXIBILITY } from '@Constants/Flexibility';
import { createStore, Store } from '@Core/createStore';
import { removeFunctionMembers } from '@Core/utils';
import { ISO_DATE_FORMAT, utcDate } from '@Dates/dates';

// masterId is typed as `ID` in Aurora which is stringified in the GraphQL response,
// but in many cases we treat that as number (e.g. when parsing from the url on P&A).
// GraphQL accepts both strings and numbers as query variables for `ID` fields, so
// storing both strings and numbers in the store for masterIds does not cause any issues,
// but the best would be to treat that in a unified way.
type ID = string | number;
export type SourceParam = 'srp' | 'offer-search';

interface BaseSearchSelectionStore {
  departureAirports: string[];
  destinationIds: string[];
  flexibility: number; // @TODO: enums?
  nights: number;
  rooms: RoomConfigurationInput[];
  sort: SortOption;
  filters: DynamicPackageFiltersInput;
}

type SrpViewMode = 'list' | 'map';

export const isFamily = (rooms: RoomConfigurationInput[]) =>
  rooms.some((room) => !!room.childAges.length);

export interface SearchSelectionStore extends BaseSearchSelectionStore {
  boardBasis: string[];
  cancellationPolicy: CancellationPolicy[];
  includedCheckedBags: number[];
  maxFlightStops: number[];
  date: Dayjs | undefined;
  masterId?: ID;
  masterIds: ID[];
  pinnedMasterIds: ID[];
  resultsStartIndex: number;
  source?: SourceParam;
  viewMode: SrpViewMode;

  setSort: (sort: SortOption) => void;
  setBoardBasis: (boardBasisIds: string[]) => void;
  setCancellationPolicy: (cancellationPolicy: CancellationPolicy[]) => void;
  setIncludedCheckedBags: (includedCheckedBags: number | undefined) => void;
  setMaxFlightStops: (number: number[]) => void;
  setViewMode: (viewMode: SrpViewMode) => void;
  setDate: (date: Dayjs | undefined, flexibility?: number) => void;
  setDateOnly: (date: Dayjs | undefined) => void;
  setDepartureAirports: (departureAirports: string[]) => void;
  setDestinationIds: (destinationIds: string[]) => void;
  setFlexibility: (flexibility: number) => void;
  setMasterId: (masterId: ID) => void;
  setNights: (nights: number) => void;
  setRooms: (rooms: RoomConfiguration[]) => void;
  setFilterSelection: (filters: Partial<DynamicPackageFiltersInput>) => void;
  resetFilters: () => void;
  hasActiveFilters: () => boolean;
  setResultsStartIndex: (index: number) => void;
  clearPinnedMasterIds: () => void;
  removePinnedMasterId: (id: ID) => void;
  toUrl: (pageType: PageType) => string;
  toCookie: () => string;
  isFamilySearch: () => boolean;
  isSpecificDateSelected: () => boolean;
  isMonthSelected: () => boolean;
  extend: (overrides?: Partial<SearchSelectionStore>) => SearchSelectionStore;
  setInitialValues: (overrides?: SearchSelectionStoreInitialValue) => void;
  resetSearchSelection: () => void;
}

const stateToUrlMapping: Partial<Record<PageType, (keyof SearchSelectionStore)[]>> = {
  panda: [
    'masterId',
    'departureAirports',
    'boardBasis',
    'nights',
    'rooms',
    'date',
    'cancellationPolicy',
    'maxFlightStops',
    'includedCheckedBags',
  ],

  srp: [
    'destinationIds',
    'pinnedMasterIds',
    'departureAirports',
    'nights',
    'rooms',
    'date',
    'flexibility',
    'sort',
    'filters',
  ],

  landingseo: ['destinationIds', 'departureAirports', 'nights', 'rooms', 'date', 'flexibility'],

  favourites: ['departureAirports', 'date', 'flexibility', 'nights', 'rooms'],
};

export const stateToCookieFields: (keyof SearchSelectionStore)[] = [
  'destinationIds',
  'departureAirports',
  'nights',
  'date',
  'flexibility',
  'rooms',
  'cancellationPolicy',
  'includedCheckedBags',
  'maxFlightStops',
  'filters',
  'sort',
];

const reducer =
  <T>(set: SetState<SearchSelectionStore>, fieldName: keyof SearchSelectionStore) =>
  (value: T) =>
    set((state: SearchSelectionStore) => ({ ...state, [fieldName]: value }));

export const defaultFilterValues: DynamicPackageFiltersInput = {
  boardBasis: [],
  holidayTypes: [],
  hotelFacilities: [],
  kids: [],
  maxPerPersonPrice: undefined,
  perPersonBudget: undefined,
  minTripAdvisorRating: undefined,
  minHolidayCheckRating: undefined,
  pools: [],
  propertyTypes: [],
  starRating: [],
  regions: [],
  resorts: [],
  cancellationPolicies: [],
  includedCheckedBags: [],
  maxFlightStops: [],
  location: undefined,
};

export const defaultSortValue = 'POPULAR';

const defaultSearchSelection: BaseSearchSelectionStore = {
  departureAirports: [],
  destinationIds: [],
  flexibility: DEFAULT_FLEXIBILITY,
  nights: 7,
  rooms: [
    {
      adults: 2,
      childAges: [],
    },
  ],
  sort: defaultSortValue,
  filters: {
    ...defaultFilterValues,
  },
};

export type SearchSelectionStoreInitialValue = Partial<
  Omit<SearchSelectionStore, 'date'> & {
    date?: Dayjs | string | undefined; // we can convert strings to Dayjs
  }
>;

export const createSearchSelectionStore = (
  initialValues: SearchSelectionStoreInitialValue = {},
): Store<SearchSelectionStore> =>
  createStore<SearchSelectionStore>(
    (set, get) => ({
      masterIds: [],
      pinnedMasterIds: [],
      boardBasis: [],
      cancellationPolicy: [],
      maxFlightStops: [],
      includedCheckedBags: [],
      viewMode: 'list',
      departureAirports: [],
      destinationIds: [],
      flexibility: DEFAULT_FLEXIBILITY,
      nights: 7,
      rooms: [
        {
          adults: 2,
          childAges: [],
        },
      ],
      resultsStartIndex: 0,
      sort: defaultSortValue,
      ...initialValues,
      date: (() => {
        const { date } = initialValues;

        if (dayjs.isDayjs(date)) {
          return date;
        }

        if (typeof date === 'string') {
          return utcDate(date, ISO_DATE_FORMAT);
        }

        return;
      })(),
      filters: {
        ...defaultFilterValues,
        ...(initialValues.filters as SearchSelectionStore['filters']),
      },

      setSort: reducer<SortOption>(set, 'sort'),
      setBoardBasis: reducer<string[]>(set, 'boardBasis'),
      setCancellationPolicy: reducer<string[]>(set, 'cancellationPolicy'),
      setIncludedCheckedBags: (includedCheckedBags) => {
        set((state) => ({
          ...state,
          includedCheckedBags: includedCheckedBags !== undefined ? [includedCheckedBags] : [],
        }));
      },
      setMaxFlightStops: reducer<number[]>(set, 'maxFlightStops'),
      setViewMode: reducer<SrpViewMode>(set, 'viewMode'),
      setDate: (date, flexibility = DEFAULT_FLEXIBILITY) =>
        set({
          date,
          flexibility,
        }),
      setDateOnly: (date) =>
        set((state) => ({
          ...state,
          date,
          flexibility:
            isMonthFlexibility(state.flexibility) || !state.flexibility
              ? DEFAULT_FLEXIBILITY
              : state.flexibility,
        })),
      setDepartureAirports: reducer<string[]>(set, 'departureAirports'),
      setDestinationIds: reducer<string[]>(set, 'destinationIds'),
      setFlexibility: reducer<number>(set, 'flexibility'),
      setMasterId: reducer<ID>(set, 'masterId'),
      setNights: reducer<number>(set, 'nights'),
      setRooms: reducer<RoomConfiguration[]>(set, 'rooms'),
      setFilterSelection: (overrides) =>
        set((state) => ({
          ...state,
          filters: {
            ...state.filters,
            ...overrides,
          },
        })),
      resetFilters: () =>
        set((state) => ({
          ...state,
          filters: {
            ...defaultFilterValues,
          },
        })),
      hasActiveFilters: () => {
        const { filters } = get();

        return Object.values(filters).some((value) =>
          Array.isArray(value) ? !!value.length : !!value,
        );
      },
      setResultsStartIndex: reducer<number>(set, 'resultsStartIndex'),
      clearPinnedMasterIds: () =>
        set(() => ({
          pinnedMasterIds: [],
        })),
      removePinnedMasterId: (id) => {
        set((state) => ({
          pinnedMasterIds: state.pinnedMasterIds.filter((mid) => mid !== id),
        }));
      },
      toUrl: (pageType) => {
        const fields = (stateToUrlMapping[pageType] || []) as string[];
        const state = get();

        return getUrlFromSearchSelection(state, fields);
      },
      toCookie: () => {
        const state = get();

        return getUrlFromSearchSelection(state, stateToCookieFields as string[]);
      },
      isFamilySearch: () => {
        const state = get();

        return isFamily(state.rooms);
      },
      isSpecificDateSelected: () => {
        const state = get();

        return !!state.date && !isMonthFlexibility(state.flexibility);
      },
      isMonthSelected: () => {
        const state = get();

        return !!state.date && isMonthFlexibility(state.flexibility);
      },
      resetSearchSelection: () => {
        set(() => ({
          ...defaultSearchSelection,
        }));
      },
      extend: (overrides = {}) =>
        createSearchSelectionStore({
          ...get(),
          ...overrides,
        }).getState(),
      setInitialValues: (overrides: SearchSelectionStoreInitialValue = {}) => {
        const extended = createSearchSelectionStore(overrides).getState();
        set(removeFunctionMembers(extended) as SearchSelectionStore);
      },
    }),
    'SearchSelectionStore',
  );
