import { sendEvent } from '../sendEvent';
import { TrackingEvent, EcommerceTrackingObject, EcommerceProduct, ActionField } from '../types';

export const useProductDetailsViewTracking =
  () => (products: EcommerceProduct[], actionField?: ActionField) => {
    const trackingObject: EcommerceTrackingObject = {
      event: TrackingEvent.productDetailsView,
      list: actionField?.list,
      ...products[0],
    };

    sendEvent(trackingObject);
  };
