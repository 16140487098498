import { sendEvent } from '../sendEvent';
import { SearchActionTrackingObject, TrackingEvent } from '../types';
import { SearchAvailabilityStore } from '@Stores/SearchAvailabilityStore';
import { SearchResultsStore } from '@Stores/SearchResultsStore';
import { SearchSelectionStore } from '@Stores/SearchSelectionStore';
import { createSearchTrackingObject } from '@Stores/searchStoreTracker';

export const useSearchChangeTracking = () => {
  return (
    selection: SearchSelectionStore,
    availability: SearchAvailabilityStore,
    isInitial?: boolean,
    results?: SearchResultsStore,
  ) => {
    const trackingObject: SearchActionTrackingObject = {
      event: TrackingEvent.searchChange,
      ...createSearchTrackingObject(selection, availability, results),
      isInitial,
    };

    sendEvent(trackingObject);
  };
};
