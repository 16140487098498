import { sendEvent } from '../sendEvent';
import { TrackingEvent, EcommerceTrackingObject, EcommerceProduct, ActionField } from '../types';

// https://developers.google.com/tag-manager/enhanced-ecommerce#product-clicks
export const useProductClickTracking =
  () => (products: EcommerceProduct[], actionField?: ActionField, eventCallback?: () => void) => {
    const trackingObject: EcommerceTrackingObject = {
      event: TrackingEvent.productClick,
      list: actionField?.list,
      ...products[0],
      eventCallback,
    };

    sendEvent(trackingObject);
  };
