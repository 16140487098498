import { useInViewTracking } from './useInViewTracking';
import { TrackingEvent, EcommerceTrackingObject, EcommerceProductImpression } from '../types';

// https://developers.google.com/tag-manager/enhanced-ecommerce#product-impressions
export const useProductImpressionTracking = (
  product: EcommerceProductImpression,
  disabled?: boolean,
) => {
  const trackingObject: EcommerceTrackingObject = {
    event: TrackingEvent.productImpression,
    ...product,
  };

  return useInViewTracking(trackingObject, disabled);
};
