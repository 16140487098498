import { CountryCode, Currency, PageType, ProductTrackingList } from '@AuroraTypes';
import { ConnectionInfo } from '@Core/detectConnection';
import type { Option } from '@UX/Forms/MultiSelect/MultiSelect';

export enum TrackingEvent {
  destinationAutocomplete = 'DestinationAutocomplete',
  pageView = 'Page',
  productClick = 'Product Click',
  productDetailsView = 'Product Viewed',
  productAdded = 'Product Added',
  productRemoved = 'Product Removed',
  productPayment = 'Product Payment',
  productImpression = 'Product List Viewed',
  // The checkout eventing here is based on the Rudderstack events spec
  // https://www.rudderstack.com/docs/event-spec/ecommerce-events-spec/ordering/
  checkoutStarted = 'Checkout Started',
  checkoutStepViewed = 'Checkout Step Viewed',
  checkoutPartnerInteraction = 'Checkout Partner Interaction',
  checkoutStepCompleted = 'Checkout Step Completed',
  checkoutCompleted = 'Order Completed',
  searchChange = 'Search',
  alternativeFlightsChange = 'Alternative Flights Search',
  webEvent = 'WebEvent',
  experiment = 'Experiment',
  identify = 'Identify',
  favourites = 'Favourites',
  formError = 'Form Error',
  couponEntered = 'Coupon Entered',
  couponApplied = 'Coupon Applied',
  couponDenied = 'Coupon Denied',
  feedback = 'feedback',
  checkoutTimeIsUp = 'Checkout Time Is Up',
  payment = 'Payment',
  primerOnResumeSuccess = 'Primer On Resume Success',
  primerPaymentIdMissing = 'Primer Supplied Payment Id Missing',
  primerCardNetworkNotSupported = 'Primer Card Network Not Supported',
  primerCheckoutMountMiss = 'Primer Checkout Tried Mounting After Navigation',
  userInteractionAcknowledged = 'User Interaction Acknowledged',
  userInteractionUnacknowledged = 'Ignored Mandatory User Interaction',
  travelRequirementsLinkUsed = 'Travel Requirements Link Used',
  map = 'Map',
  popularFilterUsed = 'Popular Filter Used',
  eventsProm = 'Eventsprom',
  paymentOptionsViewed = 'Payment Options Viewed',
  paymentFormViewed = 'Payment Form Viewed',
}

export enum ProductType {
  dynamicPackage = 'HL',
  hotelAndFlight = 'HF',
  hotelOnly = 'HO',
}

export interface TrackingObject {
  event: TrackingEvent;
  action?: string;
  label?: string;
  pageID?: string;
  pageType?: PageType;
  [key: string]: any;
  eventCallback?: () => void;
}
export interface GTMTrackingObject {
  event:
    | 'pageview'
    | 'searchAction'
    | 'Experiment'
    | 'DestinationAutocomplete'
    | 'eec.detail'
    | 'eec.addToCart'
    | TrackingEvent.webEvent
    | 'trackConf';
  [key: string]: any;
}

// -- Web Events
export enum WebEventCategory {
  click = 'click',
  keyboard = 'keyboard',
  swipe = 'swipe',
  subscription = 'Subscription',
  error = 'error',
  view = 'view',
}

export enum SubscriptionEvent {
  optedIn = 'opted-in',
  optedOut = 'opted-out',
}

export enum ErrorEventType {
  noAvailability = 'noAvailability',
  noAccomodationFound = 'noAccomodationFound',
  errorPageShown = 'errorPageShown',
  checkoutErrorPageShown = 'checkoutErrorPageShown',
  checkoutPartnerInteraction = 'checkoutPartnerInteraction',
  searchAssertionError = 'searchAssertionError',
  failedPayment = 'failedPayment',
  'client-captcha-invalid-json' = 'client-captcha-invalid-json',
  'client-captcha' = 'client-captcha',
  'client-captcha-invalid-data' = 'client-captcha-invalid-data',
}

export enum ErrorEventTypePromMetric {
  noAvailability = 'selling_no_availability',
  noAccomodationFound = 'selling_no_accomodation_found',
  errorPageShown = 'selling_error_page_shown',
  checkoutErrorPageShown = 'selling_checkout_error_page_shown',
  checkoutPartnerInteraction = 'selling_checkout_partner_interaction',
  searchAssertionError = 'selling_search_assertion_error',
  failedPayment = 'selling_failed_payment',
  'client-captcha' = 'selling_client_captcha',
  'client-captcha-invalid-data' = 'selling_client_captcha_invalid_data',
  'client-captcha-invalid-json' = 'selling_client_captcha_invalid_json',
}

export interface SubscriptionEventTracking extends TrackingObject {
  event: TrackingEvent.webEvent;
  label: SubscriptionEvent;
}

export interface IdentifyTracking extends TrackingObject {
  userId: string;
}

export interface WebEventTrackingObject extends TrackingObject {
  event: TrackingEvent.webEvent;
  category: WebEventCategory;
}

export interface ImageGalleryTrackingObject extends WebEventTrackingObject {
  action: 'ImageGallery';
  position: number;
  masterId?: string | number;
  name?: string;
}

export interface ErrorWebEventTrackingObject extends WebEventTrackingObject {
  errorType: ErrorEventType;
}

export interface FormErrorTracking {
  fieldName: string;
  errorType: string;
  errorMessage: string | null;
  action?: string;
  [key: string]: any;
}

// -- Page Views
export interface PageViewTrackingObject extends TrackingObject, PageViewData {
  event: TrackingEvent.pageView;
}

export interface PageViewData {
  countryCode: CountryCode;
  lvhId: string;
  lvhCustomerId?: string;
  designSystem?: boolean;
  connection: ConnectionInfo;
  criteoid?: string;
}

// -- Experiments
export interface ExperimentTrackingObject extends TrackingObject {
  event: TrackingEvent.experiment;
  experimentId: string;
  experimentKey: string;
  variationId: string;
  variationName?: string;
}

// -- Search Actions
export interface SearchActionTrackingObject extends TrackingObject {
  event: TrackingEvent.searchChange | TrackingEvent.alternativeFlightsChange;
  [key: string]: any;
}

// -- DestinationAutocomplete
export enum AutocompleteEventType {
  inputChanged = 'inputChanged',
  optionSelected = 'optionSelected',
  optionsLoaded = 'optionsLoaded',
}

export type DestinationAutocompleteOption = Pick<Option, 'label' | 'value' | 'available'>;
export type DestinationAutocompleteSelectedOption = Pick<Option, 'label' | 'value'> & {
  index: number;
};

export interface DestinationAutocompleteTrackingObject extends TrackingObject {
  event: TrackingEvent.destinationAutocomplete;
  type: AutocompleteEventType;
  searchTerm: string;
  isOptionsLoaded: boolean;
  options: DestinationAutocompleteOption[] | null;
  selectedOptions: DestinationAutocompleteSelectedOption[];
  isListOpened: boolean;
}

// -- Ecommerce
//
// Based on:
//  - https://developers.google.com/tag-manager/enhanced-ecommerce
//  - https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#ecommerce-data
export interface EcommerceTrackingObject extends TrackingObject, EcommerceProduct {
  event:
    | TrackingEvent.productClick
    | TrackingEvent.productDetailsView
    | TrackingEvent.productImpression;
}

export interface EcommerceObject {
  currencyCode?: Currency;

  // Product Impression
  impressions?: EcommerceProductImpression[];

  // Product Click
  click?: {
    actionField?: ActionField;
    products: EcommerceProduct[];
  };

  // Product View
  detail?: {
    actionField?: ActionField;
    products: EcommerceProduct[];
  };

  // Add to Cart
  add?: {
    actionField?: ActionField;
    products: EcommerceProduct[];
  };

  // Remove from Cart
  remove?: {
    actionField?: ActionField;
    products: EcommerceProduct[];
  };
}

// @TODO: extend interface
export interface EcommerceProduct {
  productId?: string | number;
  masterId?: string | number;
  name?: string;
  price?: string | number;
  list?: ProductTrackingList;
  category?: ProductType;
  [key: string]: any;
}

export interface EcommerceProductImpression extends EcommerceProduct {
  list: ProductTrackingList;
}

export interface ActionField {
  list: ProductTrackingList;
}
