import loadable from '@loadable/component';
import React from 'react';

import { getContentIdFromQueryParams, getContentIdFromRouteParams } from './content-ids';
import { Route } from '../Route';
import { PageType, PathTemplatesFragment as PathTemplates } from '@AuroraTypes';
import { preloadHandOffPage } from '@Components/LayoutComponents/HandoffPage/preloadHandOffPage';
import { FeatureFlag } from '@Core/octopus/FeatureFlag';
import { LoadableGenericPage } from '@Pages/generic/LoadableGenericPage';
import { LoadablePandaPage } from '@Pages/panda/LoadableNonGenericPanda';
import { usePreloadPandaPage } from '@Pages/panda/LoadablePandaPage';
import { LoadableNonGenericSearchResultsPage } from '@Pages/search-results/LoadableNonGenericSearchResultsPage';
import { usePreloadSearchResultPage } from '@Pages/search-results/LoadableSearchResultsPage';

const withDevRoutes =
  process.env.NODE_ENV === 'development' ||
  process.env.ENVIRONMENT === 'local' ||
  (typeof window !== 'undefined' && window?.__CONTEXTS__?.App?.environment === 'local');

const devRoutes: Route[] = withDevRoutes
  ? [
      {
        path: '/demo/error',
        component: () => {
          throw new Error('Error page');
        },
        exact: true,
        pageType: 'other',
        designSystem: true,
        getContentId: () => undefined,
      },
      {
        path: '/demo',
        component: loadable(() => import('@Pages/DemoPage')),
        exact: true,
        pageType: 'other',
        designSystem: false,
        getContentId: () => undefined,
      },
    ]
  : [];

/**
 * Default header and footer values for each route
 * header: [ 'logo', 'usps', 'favorites', 'megamenu', 'campaign' ],
 * footer: { hidden: false },
 * */

const createStaticRoutes = (pathTemplates: PathTemplates): Route[] =>
  [
    {
      path: '/404.html',
      component: loadable(() => import('@Pages/error/NotFoundPage')),
      exact: true,
      pageType: 'other' as PageType,
      designSystem: true,
      getContentId: () => undefined,
      header: ['logo', 'usps', 'megamenu'],
      footer: { hidden: true },
    },
    {
      path: '/500.html',
      component: loadable(() => import('@Pages/error/ExceptionPage')),
      exact: true,
      pageType: 'other' as PageType,
      designSystem: true,
      getContentId: () => undefined,
      header: ['logo', 'usps', 'megamenu'],
      footer: { hidden: true },
    },
    {
      // Internally we call this the Handoff page but to a user this is just a loading page so thats
      // why the route differs in name
      path: '/checkout/loading',
      component: LoadableGenericPage,
      header: ['logo'],
      footer: { hidden: true },
      exact: true,
      pageType: 'generic' as PageType,
      designSystem: true,
      getContentId: getContentIdFromRouteParams('slug'),
    },
    {
      path: '/checkout/error',
      component: LoadableGenericPage,
      // This is a hack to make sure generic pages are not unmounted when navigating to this page
      // TODO: This can be removed once all routes are generic
      isGeneric: true,
      header: ['logo', 'usps', 'megamenu'],
      footer: { hidden: true },
      exact: true,
      pageType: 'generic' as PageType,
      designSystem: true,
      getContentId: getContentIdFromRouteParams('slug'),
    },
    {
      path: '/checkout:slug([^.]*)',
      component: LoadableGenericPage,
      exact: true,
      header: ['logo', 'usps', 'telephone'],
      pageType: 'generic' as PageType,
      designSystem: true,
      getContentId: getContentIdFromRouteParams('slug'),
    },
    {
      path: pathTemplates.srp,
      component: (props: Route) => (
        <FeatureFlag name="GenericSRPPanda">
          {(enabled) =>
            enabled ? (
              <LoadableGenericPage {...props} />
            ) : (
              <LoadableNonGenericSearchResultsPage {...props} />
            )
          }
        </FeatureFlag>
      ),
      isGeneric: true,
      exact: true,
      pageType: 'srp',
      designSystem: true,
      onRender: usePreloadPandaPage,
      getContentId: () => undefined,
    },
    {
      path: pathTemplates.panda,
      component: (props: Route) => (
        <FeatureFlag name="GenericSRPPanda">
          {(enabled) =>
            enabled ? (
              <LoadableGenericPage {...props} />
            ) : (
              <FeatureFlag name="FancyPanda">
                {(enabled) =>
                  enabled ? <LoadableGenericPage {...props} /> : <LoadablePandaPage {...props} />
                }
              </FeatureFlag>
            )
          }
        </FeatureFlag>
      ),
      isGeneric: true,
      exact: true,
      pageType: 'panda',
      designSystem: false,
      getContentId: getContentIdFromQueryParams('masterId'),
      onRender: preloadHandOffPage,
    },
    pathTemplates.favourites && {
      path: `${pathTemplates.favourites}:id([a-z0-9\-]+)?`,
      component: loadable(() => import('@Pages/favourites/FavouritesPage')),
      exact: false,
      pageType: 'favourites',
      designSystem: true,
      onRender: () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        usePreloadPandaPage();
        // eslint-disable-next-line react-hooks/rules-of-hooks
        usePreloadSearchResultPage();
      },
      getContentId: getContentIdFromRouteParams('id'),
    },
  ].filter(Boolean) as Route[];

const genericRoutes: Route[] = [
  {
    path: ':slug([^.]*\\.html)',
    component: LoadableGenericPage,
    isGeneric: true,
    exact: false,
    pageType: 'generic' as PageType,
    designSystem: true,
    getContentId: getContentIdFromRouteParams('slug'),
  },
  {
    path: ':slug([^.]*)',
    component: LoadableGenericPage,
    isGeneric: true,
    exact: false,
    pageType: 'generic' as PageType,
    designSystem: true,
    getContentId: getContentIdFromRouteParams('slug'),
  },
];

const createOrderedRoutes = (pathTemplates: PathTemplates) => [
  ...createStaticRoutes(pathTemplates),
  ...devRoutes,
  ...genericRoutes,
];

export const loadAllRoutes = (pathTemplates: PathTemplates) => {
  return createOrderedRoutes(pathTemplates);
};

// Used by `FeatureFlagStore` to fetch FFs for each page type
export const supportedPageTypes: PageType[] = [
  'atozdestinations',
  'content',
  'favourites',
  'flights',
  'hdp',
  'hdp_ho',
  'hdp_meta',
  'homepage',
  'landingsem',
  'landingseo',
  'other',
  'panda',
  'srp',
  'handoff',
  'offersummary',
  'passengerdetails',
  'paymentdetails',
  'paymentdetailsbookable',
  'confirmation',
  'confirmationbookable',
  'checkouterror',
];
